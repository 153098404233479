<template>
  <!-- 图文打印 -->
  <div class="printing vh-100 vw-100">
    <!-- 图文打印 -->
    <se-return></se-return>
    <div class="sz-40 fl-x-c fw-bold f-color1 pgt-160 pgb-20">已下单用户</div>
    <div class="fl-x-c sz-24 fw-400 f-color1 mgb-40">
      <span>对准扫描口</span><span class="f-color3">出示二维码</span
      ><span>或</span><span class="f-color3">输入订单号</span><span>打印</span>
    </div>
    <div class="input">
      <el-input
        placeholder="请输入订单号"
        v-model="input"
        class="sz-30 f-color1"
      >
        <template slot="append">
          <div class="fl-x-c sz-28 fw-500 f-color0 w-123 h-72" @click="print">
            打印
          </div>
        </template>
      </el-input>
    </div>
    <div class="footer vw-100 pos-b bgc-2 h-550">
      <div class="sz-40 fw-bold pgt-95 f-color1 fl-x-c">新用户</div>
      <div class="sz-24 fw-400 fl-x-c f-color1 pgt-18 pgb-28">
        <span class="f-color3">扫描二维码</span>在小程序中下单<span></span>
      </div>
      <div class="fl-x-c">
        <div class="erwei w-184 h-184 fl-x-c">
          <img class="w-160 h-160" src="@/assets/img/二维码.png" alt="" />
        </div>
      </div>
      <div class="sz-20 f-color9 fl-x-c mgt-15 fw-400">容联官方小程序</div>
      <!-- 错误提示弹框 -->
      <el-dialog
        :visible.sync="popShow"
        width="80%"
        :show-close="false"
        :modal-append-to-body="false"
        center
        top="25vh"
      >
        <span class="sz-28 fw-400 f-color6 dialog-center"
          >订单号输入有误，请重新输入</span
        >
        <span slot="footer" class="dialog-footer fl-x-c dev-l-t">
          <div
            class="dialog-btn sz-24 fw-500 fl-x-c f-color0"
            @click="popShow = false"
          >
            确 定
          </div>
        </span>
      </el-dialog>
      <se-footer></se-footer>
    </div>
  </div>
</template>

<script>
import Return from "@/components/return";
import Footer from "@/components/footer";

export default {
  components: {
    "se-return": Return,
    "se-footer": Footer,
  },
  data() {
    return {
      input: "",
      popShow: false,
    };
  },
  methods: {
    // 打印
    print() {
      // this.popShow = true;
      this.$router.push({ path: "/printspeed" });
    },
  },
};
</script>

<style lang="scss" scoped>
.printing {
  background-color: #fff;
}
.input {
  margin: 0 20px;
  height: 72px;
  /deep/ .el-input {
    height: 72px;
    padding: 0;
    border: none;
    border-radius: 10px;
    /deep/ .el-input__inner {
      border: none;
      border: 1px solid #24dadb;
      border-radius: 10px 0 0 10px;
      font-size: 30px;
      color: #233b3c;
    }
    /deep/ .el-input-group__append {
      background-color: #24dadb;
      border-radius: 0px 10px 10px 0px;
    }
  }
}
.footer {
  box-shadow: inset 0px 2px 40px 0px rgba(0, 0, 0, 0.06);
  border-radius: 36px 36px 0px 0px;
}
.erwei {
  background: url("../../assets/img/二维码修饰@2x.png") no-repeat;
  background-size: 100% 100%;
}
.dialog-footer {
  padding: 30px 0;
  margin: 0 33px;
  .dialog-btn {
    width: 170px;
    height: 56px;
    background: #24dadb;
    border-radius: 10px;
  }
}
.dialog-center {
  line-height: 48px;
  display: inline-block;
  padding: 45px 33px 32px;
  width: 100%;
}
/deep/ .v-modal {
  z-index: 100 !important;
}
</style>
